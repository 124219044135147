import basePageFields from '@fragments/pages/basePageFields';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment FeatureFragment on Feature {
        ${basePageFields}
        dek
        hero {
            image {
                ...ImageSummaryFragment
            }
        }
        seo {
            ...SeoFragment
        }
    }
    ${SeoFragment}
    ${ImageSummaryFragment}
`;
